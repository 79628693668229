import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IParams {
    isReverse: boolean,
    title: string,
    imgAlt: string,
    imgSrc: string,
    date: string,
    description: string,
    url: string
}

const Achievement: React.FC<IParams> = ({ isReverse, imgAlt, date, description, imgSrc, title, url }) => {
    const { t, i18n } = useTranslation(['components\\homeView\\achievementTr']);

    const navigate = useNavigate();

    const [blockReverseCss, setBlockReverseCss] = useState<string>('');
    const [imgReverseCss, setImgReverseCss] = useState<string>('');

    useEffect(() => {
        if (isReverse) {
            setBlockReverseCss('block-reverse');
            setImgReverseCss('img-reverse');
        }
    }, []);

    return (
        <div className='achievement-block'>
            <div className={"block " + blockReverseCss}>
                <div className="block-img">
                    <img className={imgReverseCss} src={imgSrc} alt={imgAlt} />
                </div>
                <div className="block-description">
                    <div className="content-description">
                        <h3>{title}</h3>
                        <h4>{date}</h4>
                        <p>{description}</p>
                        <div className="content-center-button">
                            <div className="div-button" onClick={() => window.open(url)}>
                                <div className="center-label-button">
                                    <p>
                                        {t('button').toLocaleUpperCase()}
                                    </p>
                                </div>
                                <button>
                                    <img src="/img/components/tools/chevron-right.svg" alt="right" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Achievement;
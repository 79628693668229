import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import Footer from '../components/navigation/Footer';
import Navbar from '../components/navigation/Navbar';
import HomeView from '../views/HomeView';

const Home = () => {
    const { t, i18n } = useTranslation(['pages\\homeTr']);
    return (
        <>
            <Navbar />
            <Routes>
                <Route path="/home" element={<HomeView />} />
                <Route path="/*" element={<HomeView />} />
            </Routes>
            <Footer />
        </>
    );
};

export default Home;
import { useEffect, useState } from "react";


interface IParams {
    backgroundColor: string,
    imgAlt: string,
    imgSrc: string,
    title: string,
    description: string,
    minHeight: string
}

const SkillBlock: React.FC<IParams> = ({ description, title, backgroundColor, imgAlt, imgSrc, minHeight }) => {

    const [descriptionArray, setDescriptionArray] = useState<Array<string>>(['']);

    useEffect(() => {

        setDescriptionArray(description.split("&&"));

    }, [description])
//style={{ "minHeight": minHeight }}
    return (
        <div className="skillBlock" style={{ "background": backgroundColor }}>
            <div className="block-img">
                <div className="circle-img" style={{ "background": backgroundColor }}>
                    <img src={imgSrc} alt={imgAlt} />
                </div>
            </div>
            <div className="block-description" >
                <h2>{title}</h2>
                {
                    descriptionArray.map(description =>
                        <p key={description}>
                            {description}
                        </p>
                    )
                }
            </div>
        </div>
    );
};

export default SkillBlock;
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const { t, i18n } = useTranslation(['components\\navigation\\navbarTr']);
    const navigate = useNavigate();
    const [stickyNavBar, setStickyNavBar] = useState<string>('');
    const [navbar, setNavbar] = useState<string>('navbar ');


    let yScroll: number = 0;
    // The scroll listener
    const handleScroll = useCallback(() => {

        if (window.scrollY > 300) {

            if (window.scrollY > yScroll) {
                setStickyNavBar('hidde-navbar');
            } else {
                setStickyNavBar('display-navbar');
            }

        } else {

            setStickyNavBar('');
            setStickyNavBar('');

        }

        yScroll = window.scrollY;


    }, []);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    const [navManagement, setNavManagement] = useState<string>('');


    return (
        <div className={navbar + stickyNavBar}>
            <div className="content-navbar">
                <div className="block-logo-name">
                    <img onClick={() => navigate('/')} src="/img/logos/logo_blue.webp" alt="Logo manipogo" />
                    <div className='div-logo'>
                        <h2 onClick={() => navigate('/')}>
                            {t('name')}
                        </h2>
                    </div>
                </div>
                <div className="mobile-set-up">
                    <div className="block-logo-mobile">
                        <img src="/img/logos/logo-whitegradiant-little.webp" alt="Logo manipogo" />
                        <div className="div-logo-mobile">
                            <h2 onClick={() => navigate('/')}>
                                {t('name')}
                            </h2>
                        </div>
                    </div>
                    <div className="burger">
                        <img onClick={() => {
                            setNavbar('navbar navbar-expanded-responsive ');
                        }
                        } className='normal' src="/img/components/navigation/burger-blue.svg" alt="Menu" />
                        <img onClick={() => {
                            setNavbar('navbar ');
                        }
                        } className='expanded' src="/img/components/navigation/burger-white.svg" alt="Menu" />
                    </div>
                </div>
                <div className="menu">
                    <ul>
                        <li><a href='/' className="activated">{t('menu.home').toLocaleUpperCase()}</a></li>
                        <li><a href='/#achievement'>{t('menu.achievement').toLocaleUpperCase()}</a></li>
                        <li><a href='/#skills'>{t('menu.skills').toLocaleUpperCase()}</a></li>
                        <li><a href='/#footer'>{t('menu.contact').toLocaleUpperCase()}</a></li>
                        <li className="social-networks-mobile">
                            <a onClick={() => window.open(t('social-networks.instagram'))}><img src="/img/components/navigation/instagram.svg" alt="Instagram manipogo" /></a>
                            <a href={"mailto:" + t('social-networks.mail')}><img src="/img/logos/little-logo-blue.svg" alt="Mail manipogo" /></a>
                            <a onClick={() => window.open(t('social-networks.linkedIn'))}><img src="/img/components/navigation/linkedin.svg" alt="LinkedIn manipogo" /></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Navbar;

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import './i18n';
import { Suspense, useEffect } from 'react';
import Translate from './components/tools/Translate';
import HomeAdmin from './pages/HomeAdmin';
import Loader from './components/tools/Loader';


function App() {

  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<HomeAdmin />} />
          <Route path="*" element={<Home />} />
        </Routes>

      </BrowserRouter>
      
    </Suspense>
  );
}

export default App;

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Achievement from '../components/homeView/AchievementBlock';
import SkillBlock from '../components/homeView/SkillBlock';

const HomeView = () => {
    const { t, i18n } = useTranslation(['views\\homeViewTr']);

    const [btnACss, setBtnACss] = useState<string>('');

    // The scroll listener
    const handleScroll = useCallback(() => {

        if (window.scrollY > 300) {

            setBtnACss('hidde-a');

        }
        if (window.scrollY === 0) {

            setBtnACss('');

        }


    }, []);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    return (
        <div className='home-view'>
            <div className="main-content">
                <div className="content">
                    <div className="logo">
                        <img className='display-logo' src="/img/logos/logo_blue.webp" alt="Logo manipogo" />
                    </div>
                    <div className="block-description display-from-right">
                        <div className="center-content">
                            <h1>{t('title').toUpperCase()}</h1>
                            <h2>{t('description')}</h2>
                        </div>
                    </div>
                </div>
                <a className={btnACss} href='/#achievement'>
                    <img src="/img/views/homeView/arrow-down.svg" alt="Down" />
                </a>
            </div>
            <div id='achievement' className="achievement">
                <div className="container">
                    <h2>{t('achievement').toUpperCase()}</h2>
                    <div className="block-achievement">
                        <Achievement
                            isReverse={false}
                            title={t('achievements.surfperspective.title')}
                            imgAlt={t('achievements.surfperspective.imgAlt')}
                            imgSrc={t('achievements.surfperspective.imgSrc')}
                            date={t('achievements.surfperspective.date')}
                            description={t('achievements.surfperspective.description')}
                            url={t('achievements.surfperspective.url')}
                        />
                    </div>
                    <div className="block-achievement">
                        <Achievement
                            isReverse={true}
                            title={t('achievements.oniverse.title')}
                            imgAlt={t('achievements.oniverse.imgAlt')}
                            imgSrc={t('achievements.oniverse.imgSrc')}
                            date={t('achievements.oniverse.date')}
                            description={t('achievements.oniverse.description')}
                            url={t('achievements.oniverse.url')}
                        />
                    </div>
                    <div className="block-achievement">
                        <Achievement
                            isReverse={false}
                            title={t('achievements.campagne.title')}
                            imgAlt={t('achievements.campagne.imgAlt')}
                            imgSrc={t('achievements.campagne.imgSrc')}
                            date={t('achievements.campagne.date')}
                            description={t('achievements.campagne.description')}
                            url={t('achievements.campagne.url')}
                        />
                    </div>
                </div>
            </div>
            <div className="skills" id='skills'>
                <div className="container">
                    <h2>{t('skill').toUpperCase()}</h2>
                    <div className="skills-block">
                        <div className="skill">
                            <SkillBlock
                                backgroundColor={t('skills.database.backgroundColor')}
                                description={t('skills.database.description')}
                                imgAlt={t('skills.database.imgAlt')}
                                imgSrc={t('skills.database.imgSrc')}
                                title={t('skills.database.title')}
                                minHeight={"520px"}
                            />
                        </div>
                        <div className="skill">
                            <SkillBlock
                                backgroundColor={t('skills.back.backgroundColor')}
                                description={t('skills.back.description')}
                                imgAlt={t('skills.back.imgAlt')}
                                imgSrc={t('skills.back.imgSrc')}
                                title={t('skills.back.title')}
                                minHeight={"520px"}
                            />
                        </div>
                        <div className="skill">
                            <SkillBlock
                                backgroundColor={t('skills.front.backgroundColor')}
                                description={t('skills.front.description')}
                                imgAlt={t('skills.front.imgAlt')}
                                imgSrc={t('skills.front.imgSrc')}
                                title={t('skills.front.title')}
                                minHeight={"520px"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeView;